<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue"

import { AccordionHeader, AccordionTrigger, type AccordionTriggerProps } from "radix-vue"

const props = defineProps<AccordionTriggerProps & { class?: HTMLAttributes["class"] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <AccordionHeader class="flex">
    <AccordionTrigger
      v-bind="delegatedProps"
      :class="
        cn(
          'flex flex-1 items-baseline justify-between py-4 text-start font-semibold transition-all hover:underline md:pb-6 md:pt-5 [&[data-state=open]_.plus-icon]:rotate-45',
          props.class
        )
      "
    >
      <slot />
      <slot name="icon">
        <IconAdd class="plus-icon h-4 w-4 transition-transform duration-200" fill="black" />
      </slot>
    </AccordionTrigger>
  </AccordionHeader>
</template>
