<template>
  <slot />
</template>

<script setup lang="ts">
import type { PricingProductEnum } from "@finq/pricing/types"

const props = withDefaults(defineProps<{ productId?: PricingProductEnum }>(), {
  productId: undefined,
})

useProvidePricingStore({ productId: props.productId })
</script>

<i18n lang="json">
{
  "en": {
    "no_data": {
      "title": "We're struggling at the moment to display our products.",
      "text": "Please try again later."
    },
    "status": {
      "UPCOMING": "Upcoming"
    },
    "period": {
      "ANNUAL": {
        "label": "Year",
        "hint": "Billed annually. No commitment. Cancel anytime."
      },
      "MONTHLY": {
        "label": "Month",
        "hint": "Billed monthly. No commitment. Cancel anytime."
      },
      "FEES": {
        "hint": "Management fee per year"
      },
      "FREE": {
        "label": "Free",
        "hint": "Email required"
      }
    },
    "terms": {
      "title": "Subscription terms"
    },
    "buttons": {
      "select": "Get started",
      "selected": "Selected",
      "notify_me": "Notify me",
      "notify_me_selected": "We'll notify you",
      "active_plan": "Active plan",
      "downgrade": "Downgrade",
      "upgrade": "Upgrade"
    },
    "categories": {
      "PRODUCT_STOCK": "Stocks",
      "PRODUCT_PENSION": "Pensions",
      "PRODUCT_FUND": "Mutual funds"
    },
    "change_plan": {
      "title": "Downgrade to {planName} plan",
      "cancel": {
        "description": "To downgrade to this plan, you need to first cancel your current {planName} plan in your profile under Subscription.",
        "cta_text": "Go to your profile"
      },
      "already_canceled": {
        "description": "Your {planName} plan is active until {expiryDate} After this date, you will be able to switch to another plan.",
        "cta_text": "Ok"
      }
    }
  },
  "he": {
    "no_data": {
      "title": "אנחנו נתקלים כרגע בבעיה בהצגת תמחור המוצרים.",
      "text": "אנא נסה שוב מאוחר יותר."
    },
    "status": {
      "UPCOMING": "בקרוב"
    },
    "period": {
      "ANNUAL": {
        "label": "שנה",
        "hint": "חיוב שנתי ללא החזר. ביטול בכל זמן."
      },
      "MONTHLY": {
        "label": "חודש",
        "hint": "חיוב חודשי ללא החזר. ביטול בכל זמן."
      },
      "FEES": {
        "hint": "דמי ניהול שנתיים מההשקעה. חיוב רבעוני."
      },
      "FREE": {
        "label": "חינם",
        "hint": "מותנה בהרשמה"
      }
    },
    "buttons": {
      "select": "בחירה",
      "selected": "נבחר",
      "notify_me": "עדכנו אותי",
      "notify_me_selected": "אנחנו נעדכן אותך",
      "active_plan": "תוכנית פעילה",
      "downgrade": "שנה/י תוכנית",
      "upgrade": "שנה/י תוכנית"
    },
    "terms": {
      "title": "תנאי שימוש"
    },
    "categories": {
      "PRODUCT_STOCK": "מניות",
      "PRODUCT_PENSION": "פנסיות",
      "PRODUCT_FUND": "קרנות נאמנות"
    },
    "change_plan": {
      "title": "החלפה לתוכנית {planName}",
      "cancel": {
        "description": "כדי לעבור לתוכנית זו, עליך קודם לבטל את תוכנית {planName} הנוכחית שלך בפרופיל שלך תחת מנוי.",
        "cta_text": "עבור/י לפרופיל שלי"
      },
      "already_canceled": {
        "description": "התוכנית שלך {planName} פעילה עד {expiryDate}. לאחר תאריך זה תוכל לשנות לתוכנית אחרת.",
        "cta_text": "אוקיי"
      }
    }
  }
}
</i18n>
