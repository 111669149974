<template>
  <section :class="classNames.root">
    <div :class="classNames.titleWrapper">
      <span :class="classNames.title">{{ t("section_title") }}</span>

      <slot name="subtitle">
        <i18n-t :class="classNames.subtitle" tag="p" keypath="section_subtitle">
          <template #contact_us>
            <UiLink class="underline" :to="{ name: 'contact' }">{{
              t("section_subtitle_click_here")
            }}</UiLink>
          </template>
        </i18n-t>
      </slot>
    </div>

    <UiAccordion :class="classNames.container" type="single" collapsible>
      <UiAccordionItem
        v-for="item in list"
        :class="classNames.question"
        :value="item.question"
        :key="item.question"
      >
        <UiAccordionTrigger :class="classNames.trigger">{{ item.question }}</UiAccordionTrigger>
        <UiAccordionContent :class="classNames.content">
          <p class="whitespace-pre-line">{{ item.answer }}</p>
        </UiAccordionContent>
      </UiAccordionItem>
    </UiAccordion>
  </section>
</template>

<script setup lang="ts">
export type QuestionAnswer = { question: string; answer: string }
export type FAQSectionClasses = {
  root: ClassValue
  titleWrapper: ClassValue
  title: ClassValue
  subtitle: ClassValue
  container: ClassValue
  question: ClassValue
  trigger: ClassValue
  content: ClassValue
}

export type FAQSectionProps = {
  list: QuestionAnswer[]
  classes?: Partial<FAQSectionClasses>
}
const props = defineProps<FAQSectionProps>()

const { t } = useI18n({ useScope: "local" })

const classNames = computed(
  (): FAQSectionClasses => ({
    root: cn("flex gap-10 md:flex-col", props.classes?.root),
    titleWrapper: cn("flex flex-col whitespace-pre", props.classes?.titleWrapper),
    title: cn("text-2.5xl mb-4 font-semibold", props.classes?.title),
    subtitle: cn("text-base font-light leading-none", props.classes?.subtitle),
    container: cn("w-full", props.classes?.container),
    question: cn("accordion-item", props.classes?.question),
    trigger: cn("pb-10 font-medium", props.classes?.trigger),
    content: cn("font-light", props.classes?.content),
  })
)
</script>

<i18n lang="json">
{
  "en": {
    "section_title": "Get your questions answered​",
    "section_subtitle": "Can’t find the answer here? {contact_us}",
    "section_subtitle_click_here": "Contact us"
  },
  "he": {
    "section_title": "שאלות? פה התשובות",
    "section_subtitle": "לא מצאת את מה שחיפשת? ליצירת קשר {contact_us}",
    "section_subtitle_click_here": "לחץ/י כאן"
  }
}
</i18n>
